

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component()

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Error403View extends Vue {}

